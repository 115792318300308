<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Edit Work Permit Type",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Edit Work Permit Type",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Work Permit Type",
                    href: "/master/sub-perizinan",
                },
                {
                    text: "Edit Work Permit Type",
                    active: true,
                },
            ],

            // variabel untuk option v-select
            jenisPerizinan: [],
            jenisPerizinan_selected: [],
            perizinan_selected: "",

            // variabel untuk add data
            mps_nama: "",
            mps_id: this.$route.params.mps_id,
            mps_kode_izin: "",
        };
    },
    mounted() {
        // Load data awal banget
        let self = this;

        // Swal.fire({
        //     title: '<i class="fas fa-spinner fa-spin"></i>',
        //     text: "Loading...",
        //     showConfirmButton: false,
        // });

        // // load data jenis perizinan
        // var config_ref_perizinan = {
        //     method: "get",
        //     url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-perizinan",
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: "Bearer " + localStorage.access_token,
        //     },
        // };
        // axios(config_ref_perizinan)
        //     .then(function (response) {
        //         var response_data = response.data;
        //         var response_data_fix = response_data.data;
        //         if (response_data.meta.code == 200) {
        //             self.jenisPerizinan = response_data_fix.referensi;
        //         } else {
        //             Swal.fire({
        //                 icon: "error",
        //                 title: "Oops...",
        //                 text: response_data_fix.data.message,
        //             });
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });

        // get data berdasarkan id
        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
            params: {
                mps_id: self.mps_id,
            },
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if (response_data.meta.code == 200) {
                    var data_edit = response_data_fix.data[0];
                    self.mps_nama = data_edit.mps_nama;
                    self.mps_kode_izin = data_edit.mps_kode_izin;
                    var data_perizinan = data_edit.perizinan;
                    self.perizinan_selected = data_perizinan;
                    console.log(response_data_fix);
                    Swal.close();
                } else {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.close();
            });
    },
    methods: {
        // Method dari masing masing fungsi di dalam file ini
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
                data: {
                    mps_id: self.mps_id,
                    mps_nama: self.mps_nama,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master Work Permit Type segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-sub-perizinan" });
                        }
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Name Work Permit Type" label-for="formrow-nama-sub-perizinan-input">
                                        <b-form-input id="formrow-nama-sub-perizinan-input" type="text" v-model="mps_nama"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
